import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getBooks, removeBookFromReadingList } from '../Services/Books';
import { toast } from 'react-toastify';
import { Loading } from '../Components';
import { addToCart } from '../Services/Cart';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../Helpers/Common';
import { increaseCartQuantity } from '../../Redux/Slices/UserSlice';

const ReadingList = ({setKey}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const user = useSelector((state) => state.UserReducer.value);
    const [loading, setLoading] = useState(false);
    const [books, setBooks] = useState([]);

    const getInitialPageData = async () => {
        setLoading(true)
        let result = await getBooks({ reading_list: true, remove_limit_offset: true })
        result?.success ? setBooks(result.data) : setBooks([]);
        setLoading(false)
    }

    const removeFromReadingList = async (data) => {
        setLoading(true);
        const { book_id } = data;
        let result = await removeBookFromReadingList({book_id});
        if (result.success) {
            getInitialPageData()
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        } 
        setLoading(false)
    }

    const addToUsersCart = async (data) => {
        const { book_id, cart_id, order_id, order_status, returned_date, borrowing_id } = data;

        if (cart_id) {
            navigate(urlPrefix() + "/order?key=OrderNow");
        }
        else if (order_id && ["pending", "approved"].includes(order_status)) {
            setKey("Order History")
        }
        else if (borrowing_id && !returned_date) {
            navigate(urlPrefix() + "/order?key=ReturnNow");
        }
        else {
            setLoading(true)
            const result = await addToCart({ book_id, cart_id: user.cart_id, quantity: 1 });
            if (result?.success) {
                toast.success(result.message)
                dispatch(increaseCartQuantity())

                // After adding to cart removing from reading list by just calling the api not the fx in arder to prevent toast
                await removeBookFromReadingList({book_id});

                getInitialPageData()
            } else {
                toast.error(result.error.message)
            }
            setLoading(false)
        }
    }


    const bookLabel = (data) => {
        const { cart_id, order_id, order_status, returned_date, borrowing_id } = data
        if (borrowing_id && !returned_date) {
            return "Borrowed"
        }
        else if (order_id && ["pending", "approved"].includes(order_status)) {
            return "Ordered"
        }
        else if (cart_id) {
            return "Go to Cart"
        }
        else {
            return "Add to Cart"
        }
    }


    useEffect(() => {
        getInitialPageData()
    }, [])

    return (

        <div className="profile1-m1">
            <h3 className="heading-h3 pro1-title">Reading List</h3>

            {
                books && books.length ?
                    books.map((book,index) => <div className="reading-list15" key={index}>
                        <div className="order-book-card">
                            <div className="book">
                                <img onClick={()=>navigate(urlPrefix() + `/library-detail/${book.slug}`)} src={book.image_url} alt="book imag"></img>
                            </div>
                            <div className="detail">
                                <div className='top-row reading-t15 reding-list-vs1'>
                                    <div className="order-n">
                                        <h3 className="o-title heading-h3" onClick={()=>navigate(urlPrefix() + `/library-detail/${book.slug}`)}>{book.title}</h3>
                                        <p className="o-sub-title">&nbsp; </p>
                                        {/* <p className="o-sub-title" onClick={()=>navigate(urlPrefix() + `/library-detail/${book.slug}`)}>{book.title}</p> */}
                                    </div>
                                    <div className="order-s-date">
                                        <p className="o-title">&nbsp; </p>
                                        <button className="accept-reject" onClick={()=>removeFromReadingList(book)}>Remove</button>
                                        <button className="accept-reject" onClick={()=>{addToUsersCart(book)}}>{bookLabel(book)}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                    : <div className='no-data'>No books yet</div>
            }
            {
                loading ? <Loading/> : null
            }
        </div>
    )
}

export default ReadingList;