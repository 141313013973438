import React, { useEffect, useState } from "react";
import { CButton, Loading, LoginPopUp, YouAlsoLike } from "../Components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleBook, insertBookInReadingList, sendOutOfStockNotification } from "../Services/Books";
import { addToCart } from "../Services/Cart";
import { toast } from "react-toastify";
import { displayDimensions, urlPrefix } from "../Helpers/Common";
import { increaseCartQuantity } from "../../Redux/Slices/UserSlice";

const LibraryDetail = () => {
    const navigate = useNavigate();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const { slug } = useParams();
    const [loading, setLoading] = useState(false);
    const [bookData, setBookData] = useState("");
    const dispatch = useDispatch();
    const user = useSelector((state) => state.UserReducer.value);
    const [showMostInDemand, setShowMostInDemand] = useState(true)
    const [notificationSent, setNotificationSent] = useState(false)
    

    const addToReadingList = async (data) => {
        const { reading_list_id, id } = data;
        if (!user.authenticated) {
            navigate(urlPrefix() + "/login")
        }
        else if (reading_list_id) {
            navigate(urlPrefix() + "/subscription-account?key=Reading List");
            return
        }
        else {
            let result = await insertBookInReadingList({ book_id: id });
            if (result?.success) {
                toast.success(result.message)
                getInitialPageData()
            } else {
                toast.error(result.error.message)
            }
        }
    }

    const addToUsersCart = async (data) => {
        const { id, cart_id, borrowed, ordered } = data;

        if (!user.authenticated) {
            navigate(urlPrefix() + "/login")
        }
        else if (cart_id) {
            navigate(urlPrefix() + "/order?key=OrderNow");
        }
        else if (ordered) {
            navigate(urlPrefix() + "/subscription-account?key=Order History");
        }
        else if (borrowed) {
            navigate(urlPrefix() + "/order?key=ReturnNow");
        }
        else {
            const result = await addToCart({ book_id: id, cart_id: user.cart_id, quantity: 1 });
            if (result?.success) {
                dispatch(increaseCartQuantity())
                toast.success(result.message)
                getInitialPageData()
            } else {
                toast.error(result.error.message)
            }
        }
    }

    const bookLabel = (data) => {
        const { cart_id, borrowed, ordered } = data
        if (borrowed) {
            return "Borrowed"
        }
        else if (ordered) {
            return "Ordered"
        }
        else if (cart_id) {
            return "Go to Cart"
        }
        else {
            return "Add to Cart"
        }
    }

    const getInitialPageData = async (data = null) => {
        setLoading(true)
        const result = await getSingleBook({ book_slug: data ?? slug });
        setLoading(false);
        result.success ? setBookData(result.data) : navigate(-1)
    }

    const sendNotification = async (book) => {
        setLoading(true)
        if (book?.id && user?.id) {
            const result = await sendOutOfStockNotification({ book_id: book.id, user_id: user.id});
            if (result?.success) {
                getInitialPageData()
                toast.success(result.message)
            } else {
                toast.error(result.error.message)
                navigate(-1)
            } 
            
        }
        setLoading(false);
    }

    useEffect(() => {
        getInitialPageData()
    }, [user.authenticated])


    return (
        <React.Fragment>
            {
                bookData ?
                    <>
                        <div className="library-overview-hero detail detail-v1">
                            <div className="house_container">
                                <div className="book-detail-con">
                                    <div className="left">
                                        <div className="left cost-back">
                                            <CButton fxToRun={() => navigate(urlPrefix() + "/library-overview")} label="Library" custClassName="header-btn-y t-btn"></CButton>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <h1 className="heading-h1 overview-title login-title">{bookData.book.title}</h1>
                                        <h5 className="heading-h5 overview-detail">{bookData?.authors?.map(author => author.name).join(', ')}</h5>
                                        {/* {
                                            bookData.book.rating > 0 ?
                                                <div className="reviews">
                                                    <div className="icon">
                                                        {Array.from({ length: bookData.book.rating }, (_, index) => index).map((item) => <img className="google-reviews" src={urlPrefix() + "/assets/image/Icon-ionic-ios-star.png"} alt='total reviews' />)}
                                                        {Array.from({ length: 5 - bookData.book.rating }, (_, index) => index).map((item) => <img className="google-reviews" src={urlPrefix() + "/assets/image/Icon ionic-ios-star-blank.png"} alt='total reviews' />)}
                                                    </div>
                                                    <div className="total">
                                                        <p>{bookData.book.rating}/5</p>
                                                    </div>
                                                </div> :
                                                null
                                        } */}

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="product-detail duct-pad">
                            <div className="product">
                                <img className="google-reviews" src={bookData.book.image_url} alt='total reviews' />
                            </div>
                            <div className="detail">
                                <div className="book-category">
                                    {/* <div className="title">
                                        <p>{bookData.categories[0].name}</p>
                                    </div> */}
                                    <div className="category-list">
                                        <ul>
                                            {
                                                bookData?.publisher?.name ? <li>{bookData?.publisher?.name}</li> : null
                                            }
                                            {
                                                bookData?.book?.ISBN13 || bookData?.book?.ISBN10 ? <li>{bookData.book.ISBN13 ?? bookData.book.ISBN10}</li> : null
                                            }
                                            {
                                                bookData?.book?.total_pages ? <li>{bookData.book.total_pages} Pages </li> : null
                                            }
                                            {
                                                displayDimensions(bookData?.book?.dimensions) ? <li>{displayDimensions(bookData.book.dimensions)}</li> : null
                                            }
                                            {
                                                bookData?.book?.language.toUpperCase() ? <li>{bookData.book.language.toUpperCase()}</li> : null
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="add-cart-cta">
                                    <div className="left">
                                        {
                                            bookData.book.quantity_available > 0 ? <button className="add-to-cart t-btn w-100" onClick={() => addToUsersCart(bookData.book)}><span>&nbsp;</span>{bookLabel(bookData.book)}</button> : null
                                        }
                                        {
                                            bookData.book.quantity_available <= 0 ?
                                                <button onClick={() => (!bookData?.book?.out_of_stock_notification) ? sendNotification(bookData.book) : null} className="notify-btn t-btn">{bookData?.book?.out_of_stock_notification ? "Notification Sent" : "Notify when available"}</button> :
                                                null
                                        }
                                    </div>
                                    <div className="right">
                                        <button onClick={() => addToReadingList(bookData.book)} className="reading-cart t-btn"><span>&nbsp;</span>{bookData.book.reading_list_id ? "Reading List" : "Add to List"}</button>
                                    </div>
                                </div>

                                <div className="day-delivery">
                                    {
                                        bookData.book.quantity_available > 0 ?
                                            <p className="in-stock">In Stock</p> :
                                            <p className="out-stock">Out of Stock</p>
                                    }
                                </div>

                                {
                                    bookData.book.why_we_love_it ?
                                        <div className="book-detail ">
                                            <p className="detail-title">Why we love it</p>
                                            <p>{bookData.book.why_we_love_it}</p>
                                        </div> :
                                        null
                                }



                                <div className="book-detail bt-0">
                                    <p className="detail-title">Synopsis</p>
                                    <div dangerouslySetInnerHTML={{ __html: bookData.book.synopsis }} />
                                </div>

                                <div className="casualties">
                                    {/* <div className="title">
                                        <p>Civilian war casualties</p>
                                    </div> */}
                                    <div className="category-list">
                                        <ul>
                                            {
                                                bookData.categories.map((item) => <li>{item.name}</li>)
                                            }
                                            {/* <li>Miliartism</li>
                                            <li>US - Military Policy</li>
                                            <li>US - Military Policy</li> */}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {
                            showMostInDemand ?
                                <>
                                    <div className="you-like-title">
                                        <p className="heading-h2">You may also like</p>
                                    </div>
                                    <div className="detail demand-slider det852">
                                        <YouAlsoLike changeLibraryDetailBook={getInitialPageData} categoryId={bookData.categories[0].category_id} rejectedBooks={[bookData.book.id]} setShowMostInDemand={setShowMostInDemand}></YouAlsoLike>
                                    </div>
                                </> :
                                null
                        }

                        <section className="becoming-member">
                            <div className="house_container">
                                <div className="image">
                                    <img src={urlPrefix() + "/assets/image/christin-hume.png"} alt="book imag"></img>
                                </div>
                                <div className="content">
                                    <h2 className="heading-h2">Becoming a Member is Easy.</h2>
                                    <p className="detail">Our members can borrow from a wide range of specially selected books with free pick-up and delivery.</p>
                                    <div className="cta">
                                        <CButton fxToRun={() => navigate(urlPrefix() + "/join-us")} label="Join Us" custClassName="white-btn-w t-btn"></CButton>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </> : null
            }
            {
                loading ? <Loading /> : null
            }

            <section className="h-before-footer">
                <div className="house-row">
                    <div className="title">
                        <h4 className="heading-h2"><span>About</span> House of Books</h4>
                    </div>
                    <div className="detail">
                        <p>House of Books was established to create a community of readers. As an affordable means for people to rediscover the pleasure of reading a… <a onClick={() => navigate(urlPrefix() + "/our-story")}>Read More</a></p>
                    </div>
                    <div className="cta">
                        <CButton fxToRun={() => navigate(urlPrefix() + "/library-overview")} label="Explore Everything" custClassName="cost-btn t-btn"></CButton>
                    </div>
                </div>
            </section>

            <LoginPopUp showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
        </React.Fragment>
    );
};
export default LibraryDetail;